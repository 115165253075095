<template>
  <el-form :model="form" ref="ruleForm">
    <section>
      <i18n path="register.confirmYourId.description" tag="p">
        <template v-slot:mailTo>
          <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
        </template>
      </i18n>
    </section>
    <div class="row">
      <div class="confirmId_main clearfix">
        <div class="fl left_box">
          <div class="title">
            {{ $t('register.confirmYourId.poidTitle') }} -
            <I v-if="checkIdDocFile" class="i_success">{{ $t('register.confirmYourId.pending.verification') }}</I>
            <I class="i_error" v-else>{{ $t('register.confirmYourId.pending.upload') }}</I>
          </div>
          <p class="tip">{{ $t('register.confirmYourId.uploadDescription') }}</p>

          <ul v-html="$t('register.confirmYourId.poidTypes')"></ul>
        </div>
        <div class="fr right_box">
          <vUpload
            :limit="6"
            v-on:updateFileInfo="updateIDInfo"
            :selfTip="true"
            :selfText="true"
            :class="{ finish: checkIdDocFile }"
            data-testid="updateIDInfo"
          >
          </vUpload>
        </div>
        <idPoaForm ref="idPoaForm" v-if="form.idDocFilePathList.length > 0"></idPoaForm>
      </div>
    </div>
    <div class="row">
      <div class="confirmId_main clearfix">
        <div class="fl left_box">
          <div class="title">
            {{ $t('register.confirmYourId.porTitle') }} -
            <I v-if="checkPocDocFile" class="i_success">{{ $t('register.confirmYourId.pending.verification') }}</I>
            <I class="i_error" v-else>{{ $t('register.confirmYourId.pending.upload') }}</I>
          </div>
          <p class="tip">{{ $t('register.confirmYourId.uploadDescription') }}</p>
          <ul v-html="$t('register.confirmYourId.porTypes')"></ul>
        </div>
        <div class="fr right_box">
          <vUpload
            :limit="6"
            v-on:updateFileInfo="updatePOAInfo"
            :selfTip="true"
            :selfText="true"
            :class="{ finish: checkPocDocFile }"
            data-testid="updatePOAInfo"
          >
          </vUpload>
        </div>
      </div>
    </div>
    <vDialog :show.sync="dialog.show" :text="$t('common.field.completed')"></vDialog>
  </el-form>
</template>

<script>
import vUpload from '@/components/vUpload';
import vDialog from '@/components/vDialog';
import idPoaForm from '@/components/home/UploadIdAddress/Form';
import confirmID from '@/mixins/register/confirmID';

export default {
  components: { vUpload, vDialog, idPoaForm },
  mixins: [confirmID]
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/confirmID.scss';
</style>

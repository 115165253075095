<template>
  <el-form :model="form" :rules="rules" ref="ruleForm" class="id_form">
    <div class="title">
      {{ $t('identityProof.shufti.content') }}
    </div>
    <el-row :gutter="60">
      <el-col :xs="24" :sm="24" :md="12">
        <InputForm
          :label="$t('register.personalDetails.page1.firstName')"
          name="firstName"
          v-model="form.firstName"
          maxlength="128"
        ></InputForm>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12">
        <InputForm
          :label="$t('register.personalDetails.page1.middleName')"
          name="middleName"
          v-model="form.middleName"
          maxlength="128"
        ></InputForm>
      </el-col>
    </el-row>
    <el-row :gutter="60">
      <el-col :xs="24" :sm="24" :md="12">
        <InputForm
          :label="$t('register.personalDetails.page1.lastName')"
          name="lastName"
          v-model="form.lastName"
          maxlength="128"
        ></InputForm>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12">
        <SelectForm v-model="form.countryId" :label="$t('identityProof.shufti.countryOfDocument')" name="countryId">
          <el-option
            v-for="(item, index) in countries"
            :key="index"
            :label="item.countryName"
            :value="item.id"
            :data-testid="index"
          ></el-option>
        </SelectForm>
      </el-col>
    </el-row>
    <el-row :gutter="60" class="lessMarginBottom">
      <el-col :xs="24" :sm="24" :md="12">
        <SelectForm v-model="form.idType" :label="$t('register.personalDetails.page1.idType')" name="idType">
          <el-option
            v-for="(item, index) in identificationTypes"
            :key="index"
            :label="item.label"
            :value="item.value"
            :data-testid="index"
          ></el-option>
        </SelectForm>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12">
        <InputForm :label="idTypeLabel" name="idNumber" v-model="form.idNumber"></InputForm>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { apiGetData } from '@/resource/register';

export default {
  name: 'idPoaForm',
  data() {
    return {
      form: {
        firstName: null,
        middleName: null,
        lastName: null,
        countryId: null,
        idType: null,
        idTypeLabel: null
      },
      rules: {
        firstName: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.firstNameReq')
          },
          {
            max: 50,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 50 })
          },
          {
            min: 1,
            message: this.$t('common.formValidation.noteLessThan', { limit: 1 })
          }
        ],
        lastName: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.lastNameReq')
          },
          {
            min: 1,
            message: this.$t('common.formValidation.noteLessThan', { limit: 1 })
          },
          {
            max: 50,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 50 })
          }
        ],
        countryId: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.countryReq')
          }
        ],
        idType: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.idTypeReq')
          }
        ],
        idNumber: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.personalDetails.page1.idNum')
            })
          },
          {
            max: 45,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 45 })
          }
        ]
      },
      identificationTypes: [
        { value: 1, label: this.$t('register.personalDetails.page1.idTypeDropdown.idCard') },
        { value: 2, label: this.$t('register.personalDetails.page1.idTypeDropdown.passport') },
        { value: 3, label: this.$t('register.personalDetails.page1.idTypeDropdown.driverLicense') }
      ]
    };
  },
  mounted() {
    this.getUserDate();
    this.$store.dispatch('register/actionAllCountries', this.lang);
  },
  methods: {
    getUserDate() {
      apiGetData(5).then(resp => {
        this.form = { ...resp.data.data };
      });
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    countries() {
      return this.$store.state.register.allCountries;
    },
    idTypeLabel() {
      switch (this.form.idType) {
        case 2:
          return this.$t('register.personalDetails.page1.passportNum');
        case 3:
          return this.$t('register.personalDetails.page1.driverLicenseNum');
        default:
          return this.$t('register.personalDetails.page1.idNum');
      }
    }
  },
  watch: {
    idTypeLabel(label) {
      this.rules.idNumber[0].message = this.$t('common.formValidation.dynamicReq', {
        dynamic: this.idTypeLabel
      });
      this.$refs.ruleForm.validateField('idNumber');
    }
  }
};
</script>

export default {
  data() {
    return {
      form: {
        idDocFilePathList: [],
        poaDocFilePathList: [],
        step: 5,
      },
      dialog: {
        show: false,
      },
    }
  },
  watch: {
    'form.idDocFilePathList'(newVal, oldVal) {
      if (newVal.length >= oldVal.length) {
        this.showDialog()
      }
    },
    'form.poaDocFilePathList'(newVal, oldVal) {
      if (newVal.length >= oldVal.length) {
        this.showDialog()
      }
    },
  },
  computed: {
    checkIdDocFile() {
      return this.form.idDocFilePathList.length > 0
    },
    checkPocDocFile() {
      return this.form.poaDocFilePathList.length > 0
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.$store.dispatch('common/actionRegisterStep')
      if (this.$store.state.common.registerStep >= 6) {
        this.$router.push('/registerFinish')
      }
    },
    updateIDInfo(fileInfo) {
      this.form.idDocFilePathList = fileInfo.fileList
    },
    updatePOAInfo(fileInfo) {
      this.form.poaDocFilePathList = fileInfo.fileList
    },
    showDialog() {
      this.dialog.show = true
      setTimeout(() => {
        this.dialog.show = false
      }, 2000)
    },
  },
}
